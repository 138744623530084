<template>
  <section class="material-select-bad">
    <div class="xa-cell__box xa-cell">
      <div class="xa-flex" v-if="materialTyp === 'BAD'">
        <div class="xa-txt-primary">1.修改/移除坏件</div>
        <div class="xa-txt-secondary">请取消多余的定损坏件</div>
      </div>
      <div class="xa-flex" v-else>
        <div class="xa-txt-primary">2.确认退还的配件</div>
        <div class="xa-txt-secondary">请选择退还的配件</div>
      </div>
      <div class="xa-txt-blue" @click="onShowTip('tip')">说明</div>
    </div>
    <div v-for="(item, index) in materialList" :key="index">
      <MaterialInfo v-if="item.is_single == 0" :item="item">
        <Stepper v-model="item.count" min="0" :max="item.max" />
      </MaterialInfo>
      <MaterialSelectSn
        v-else
        :item="item"
        :result="selectSnsMap[item.code]"
        @selectAll="onSelectSnAll(item, index)"
        @select="onSelectSn"
      />
    </div>
    <EnsureSubmitBar
      :buttonText="materialTyp === 'BAD' ? '下一步' : '确定'"
      :disabled="selectedCategoryNum === 0"
      :label="submitBarLabel"
      @submit="onSubmit"
    />
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import EnsureSubmitBar from '@/components/EnsureSubmitBar'
import MaterialInfo from '@/components/material/MaterialInfo'
import MaterialSelectSn from '@/components/material/MaterialSelectSn'
import { GetMrSelectMaterials, ApplyMaterialReturn } from '@/apis/protection'
import { Dialog, Stepper } from 'vant'
export default {
  name: 'Material-Select',
  mixins: [basePage],
  config: {
    title: '移除定损坏件',
    fetchDataFn: 'initView'
  },
  components: {
    EnsureSubmitBar,
    MaterialInfo,
    MaterialSelectSn,
    Stepper
  },
  data() {
    return {
      materialList: [],
      selectSnsMap: {},
      selectedCategoryNum: 0, // 已选择的类别数
      selectedTotalNum: 0, // 已选择的件数
      calcTime: 0,
      selectedReturnBadNum: 0, // 已选择的归还坏件数
      materialTyp: 'BAD' // [BAD|GOOD]
    }
  },
  computed: {
    submitBarLabel() {
      if (this.materialTyp === 'BAD')
        return `已选 ${this.selectedCategoryNum}类,${this.selectedTotalNum}件`
      else
        return `已选 坏${this.selectedReturnBadNum}件/好${this.selectedTotalNum}件`
    }
  },
  watch: {
    materialList: {
      deep: true,
      handler() {
        clearTimeout(this.calcTime)
        this.calcTime = setTimeout(() => {
          this.getSelectResult()
        }, 300)
      }
    },
    selectSnsMap: {
      deep: true,
      handler() {
        clearTimeout(this.calcTime)
        this.calcTime = setTimeout(() => {
          this.getSelectResult()
        }, 300)
      }
    }
  },
  methods: {
    async onSubmit() {
      const selectedResult = this.getSelectResult()
      if (this.materialTyp === 'BAD') {
        this.selectedReturnBadNum = this.selectedTotalNum
        this.selectedTotalNum = 0
        this._selectedBadList = JSON.parse(JSON.stringify(selectedResult))
        this.materialTyp = 'GOOD'
        await this.$_submitDataWidthUi(this.initView())
      } else {
        // eslint-disable-next-line no-unused-vars
        const { mp_guid } = this.$route.params
        const { href } = await this.$_submitDataWidthUi(
          ApplyMaterialReturn({
            mp_guid,
            bad_materials: this._selectedBadList,
            good_materials: selectedResult
          }),
          '确认提交后，请及时返还物料，仓库确认确认收货后完成退料。'
        )
        this.$router.go(-2)
        if (href) {
          setTimeout(() => {
            this.$_handleCfgAction({
              href
            })
          }, 500)
        }
      }
    },
    getSelectResult() {
      let selectedCategoryNum = 0
      let selectedTotalNum = 0
      let selectedResult = []
      this.materialList.forEach(item => {
        if (item.is_single === 1) {
          const sns = this.selectSnsMap[item.code] || []
          if (sns.length) {
            selectedCategoryNum++
            selectedTotalNum += sns.length
            selectedResult.push({
              code: item.code,
              sns,
              count: sns.length
            })
          }
        } else {
          if (item.count) {
            selectedCategoryNum++
            selectedTotalNum += item.count
            selectedResult.push({
              code: item.code,
              count: item.count
            })
          }
        }
      })
      this.selectedCategoryNum = selectedCategoryNum
      this.selectedTotalNum = selectedTotalNum
      return selectedResult
    },
    onSelectSnAll(item, index) {
      const { code } = item
      let selectedSns = this.selectSnsMap[code]
      let waitSns = Array.from(this.materialList[index].serial_numbers)
      if (selectedSns.length < waitSns.length) {
        this.selectSnsMap[code] = waitSns
      } else {
        this.selectSnsMap[code] = []
      }
    },
    onSelectSn(item, sn) {
      let index = this.selectSnsMap[item.code].indexOf(sn)
      index > -1
        ? this.selectSnsMap[item.code].splice(index)
        : this.selectSnsMap[item.code].push(sn)
    },
    onShowTip() {
      Dialog({
        message:
          '若领料维修时发现有多余定损坏件，可退还配件，需返还仓库，仓库确认收货后完成退料。'
      })
    },
    initMaterials(materials) {
      const selectSnsMap = {} // 根据物料的code为key，组织的结果集
      const checkIsSameMap = {} //用来归并可能出现的具有相同code的物料(这里不需要区分物料类型)
      materials.forEach(item => {
        if (item.is_single === 1) {
          const code = item.code
          item.count = item.serial_numbers.length
          selectSnsMap[code] = []
          if (checkIsSameMap[code]) {
            const sameObj = checkIsSameMap[code]
            sameObj.serial_numbers.push(...item.serial_numbers) // 归并序列号
            sameObj.count = sameObj.serial_numbers.length // 归并数量
            item.disabled = true
          } else {
            checkIsSameMap[code] = item
          }
        } else {
          item.max = item.max_select_count
          item.count = 0
        }
        return item
      })
      this.selectSnsMap = selectSnsMap
      materials = materials.filter(item => item.disabled !== true)
      this.materialList = materials
    },
    async initView() {
      // eslint-disable-next-line no-unused-vars
      const { mp_guid, guid } = this.$route.params
      if (!mp_guid || !guid) return this.$router.go(-1)
      const result = await this.$_request(
        GetMrSelectMaterials({
          mp_guid,
          guid,
          filter_materials: this._selectedBadList || [],
          type: this.materialTyp
        })
      )
      this.initMaterials(result.materials)
    }
  }
}
</script>
<style lang="scss" scoped>
.material-select-bad {
  padding-bottom: 60px;
  min-height: 100vh;
}
</style>
