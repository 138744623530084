<template>
  <section class="material-select-bad">
    <div class="xa-cell__box xa-cell">
      <div class="xa-flex" v-if="materialTyp === 'BAD'">
        <div class="xa-txt-primary">1.修改/移除坏件</div>
        <div class="xa-txt-secondary">请取消多余的定损坏件</div>
      </div>
      <div class="xa-flex" v-else>
        <div class="xa-txt-primary">2.确认退还的配件</div>
        <div class="xa-txt-secondary">请选择退还的配件</div>
      </div>
      <div class="xa-txt-blue" @click="onShowTip('tip')">说明</div>
    </div>
    <div v-for="guid in wait2SelectGuids" :key="guid">
      <div class="material-guid">{{ guid }}</div>
      <div v-for="(item, index) in wait2SelectMaterials[guid]" :key="index">
        <MaterialInfo v-if="item.is_single == 0" :item="item">
          <Stepper v-model="item.count" min="0" :max="item.max" />
        </MaterialInfo>
        <MaterialSelectSn
          v-else
          :item="item"
          :result="wait2SelectSnsMaps[guid][item.code]"
          @selectAll="onSelectSnAll(guid, item, index)"
          @select="onSelectSn(guid, arguments[0], arguments[1])"
        />
      </div>
    </div>
    <EnsureSubmitBar
      :buttonText="materialTyp === 'BAD' ? '下一步' : '确定'"
      :disabled="selectedCategoryNum === 0"
      :label="submitBarLabel"
      @submit="onSubmit"
    />
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import EnsureSubmitBar from '@/components/EnsureSubmitBar'
import MaterialInfo from '@/components/material/MaterialInfo'
import MaterialSelectSn from '@/components/material/MaterialSelectSn'
import {
  GetMrSelectMaterialsMulti,
  ApplyMaterialReturnMulti
} from '@/apis/protection'
import { Dialog, Stepper } from 'vant'
export default {
  name: 'Material-Select',
  mixins: [basePage],
  config: {
    title: '移除定损坏件',
    fetchDataFn: 'initView'
  },
  components: {
    EnsureSubmitBar,
    MaterialInfo,
    MaterialSelectSn,
    Stepper
  },
  data() {
    return {
      wait2SelectGuids: [],
      wait2SelectMaterials: {}, // 待选择的物料列表
      wait2SelectSnsMaps: {}, // 待选择的单件集合 {guid:[],guid1:[]}
      materialList: [],
      selectSnsMap: {},
      selectedCategoryNum: 0, // 已选择的类别数
      selectedTotalNum: 0, // 已选择的件数
      selectedReturnBadNum: 0, // 已选择的归还坏件数
      materialTyp: 'BAD', // [BAD|GOOD]
      // eslint-disable-next-line vue/no-reserved-keys
      _calcTime: 0
    }
  },
  computed: {
    submitBarLabel() {
      if (this.materialTyp === 'BAD')
        return `已选 ${this.selectedCategoryNum}类,${this.selectedTotalNum}件`
      else
        return `已选 坏${this.selectedReturnBadNum}件/好${this.selectedTotalNum}件`
    }
  },
  watch: {
    wait2SelectMaterials: {
      deep: true,
      handler() {
        clearTimeout(this.$data._calcTime)
        this.$data._calcTime = setTimeout(() => {
          this.getSelectResult()
        }, 300)
      }
    },
    wait2SelectSnsMaps: {
      deep: true,
      handler() {
        clearTimeout(this.$data._calcTime)
        this.$data._calcTime = setTimeout(() => {
          this.getSelectResult()
        }, 300)
      }
    }
  },
  methods: {
    async onSubmit() {
      const selectedResult = this.getSelectResult()
      if (this.materialTyp === 'BAD') {
        this.selectedReturnBadNum = this.selectedTotalNum
        this.selectedTotalNum = 0
        this._selectedBadList = JSON.parse(JSON.stringify(selectedResult))
        this.materialTyp = 'GOOD'
        await this.$_submitDataWidthUi(this.initView())
      } else {
        const data = {}
        this.wait2SelectGuids.forEach(guid => {
          if (
            this._selectedBadList[guid].length > 0 ||
            selectedResult[guid] > 0
          ) {
            data[guid] = {
              bad_materials: this._selectedBadList[guid],
              good_materials: selectedResult[guid]
            }
          }
        })
        const result = await this.$_submitDataWidthUi(
          ApplyMaterialReturnMulti(data),
          '确认提交后，请及时返还物料，仓库确认确认收货后完成退料。'
        )
        this.$router.go(-1)
        if (result && result.href) {
          setTimeout(() => {
            this.$_handleCfgAction({
              href: result.href
            })
          }, 500)
        }
      }
    },
    getSelectResult() {
      let selectedCategoryNum = 0
      let selectedTotalNum = 0
      const selectedResult = {}
      for (let guid in this.wait2SelectMaterials) {
        selectedResult[guid] = []
        this.wait2SelectMaterials[guid].forEach(item => {
          if (item.is_single === 1) {
            const sns = this.wait2SelectSnsMaps[guid][item.code] || []
            if (sns.length) {
              selectedCategoryNum++
              selectedTotalNum += sns.length
              selectedResult[guid].push({
                code: item.code,
                sns,
                count: sns.length
              })
            }
          } else {
            if (item.count) {
              selectedCategoryNum++
              selectedTotalNum += item.count
              selectedResult[guid].push({
                code: item.code,
                count: item.count
              })
            }
          }
        })
      }
      this.selectedCategoryNum = selectedCategoryNum
      this.selectedTotalNum = selectedTotalNum
      return selectedResult
    },
    /**
     * @description 点击全选
     */
    onSelectSnAll(guid, item, index) {
      const { code } = item
      let selectedSns = this.wait2SelectSnsMaps[guid][code]
      let waitSns = Array.from(
        this.wait2SelectMaterials[guid][index].serial_numbers
      )
      // 如果当前选择的集合没有达到可以选择的上线 则全选，否则全不选
      if (selectedSns.length < waitSns.length) {
        this.wait2SelectSnsMaps[guid][code] = waitSns
      } else {
        this.wait2SelectSnsMaps[guid][code] = []
      }
    },
    /**
     * @description 选择某个序列号
     */
    onSelectSn(guid, item, sn) {
      let index = this.wait2SelectSnsMaps[guid][item.code].indexOf(sn)
      index > -1
        ? this.wait2SelectSnsMaps[guid][item.code].splice(index)
        : this.wait2SelectSnsMaps[guid][item.code].push(sn)
    },
    onShowTip() {
      Dialog({
        message:
          '若领料维修时发现有多余定损坏件，可退还配件，需返还仓库，仓库确认收货后完成退料。'
      })
    },
    initMaterials(materials) {
      let snsMap = {}
      const checkIsSameMap = {} //用来归并可能出现的具有相同code的物料(这里不需要区分物料类型)
      materials.forEach(item => {
        if (item.is_single === 1) {
          const code = item.code
          item.count = item.serial_numbers.length
          snsMap[code] = []
          if (checkIsSameMap[code]) {
            const sameObj = checkIsSameMap[code]
            sameObj.serial_numbers.push(...item.serial_numbers) // 归并序列号
            sameObj.count = sameObj.serial_numbers.length // 归并数量
            item.disabled = true
          } else {
            checkIsSameMap[code] = item
          }
        } else {
          item.max = item.max_select_count
          item.count = item.count || 0
        }
        return item
      })
      materials = materials.filter(item => item.disabled !== true)
      return {
        snsMap,
        materials
      }
    },
    async initView() {
      // eslint-disable-next-line no-unused-vars
      const { data } = this.$_extractRequestInfoFormRoute()
      const { mp_guids, guid } = data
      const result = await this.$_request(
        GetMrSelectMaterialsMulti({
          mp_guids,
          guid,
          filter_materials: this._selectedBadList || [],
          type: this.materialTyp
        })
      )
      let snsMaps = {}
      let guids = []
      for (let guid in result) {
        const { snsMap, materials } = this.initMaterials(result[guid])
        guids.push(guid)
        result[guid] = materials
        snsMaps[guid] = snsMap
      }
      this.wait2SelectGuids = guids
      this.wait2SelectMaterials = result
      this.wait2SelectSnsMaps = snsMaps
    }
  }
}
</script>
<style lang="scss" scoped>
.material-guid {
  margin-top: 8px;
  padding: 10px 15px;
  background-color: #fff;
}
.material-select-bad {
  padding-bottom: 60px;
  min-height: 100vh;
}
</style>
