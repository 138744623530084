/* eslint-disable no-debugger */
<template>
  <section>
    <div class="xa-cell__box xa-cell">
      <div class="xa-txt-primary xa-flex">选择领料单</div>
      <div class="xa-txt-blue" @click="onShowTip('tip')">说明</div>
    </div>
    <van-list :value="false" :finished="true">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="xa-cell__box xa-bg-white xa-line__bottom"
        @click="onItemTap(item)"
      >
        <div class="xa-flex">
          <div class="cell-title xa-cell">
            <div class="xa-flex">{{ item.title }}</div>
            <div
              class="xa-txt-regular xa-txt-14 cell-status"
              :class="statusColor[item.status]"
            >
              {{ item.status }}
            </div>
          </div>
          <div>{{ item.warehouse }}</div>
          <div class="xa-cell">
            <div class="xa-flex">{{ item.express_type }}</div>
            <div class="xa-txt-12 xa-txt-secondary">{{ item.create_at }}</div>
          </div>
        </div>
      </div>
    </van-list>
  </section>
</template>
<script>
import basePage from '@/mixins/basePage'
import { Dialog, List } from 'vant'
import { GetMpList } from '@/apis/protection'
export default {
  name: 'Material-Apply-List',
  mixins: [basePage],
  components: {
    VanList: List
  },
  config: {
    title: '申请退料',
    fetchDataFn: 'fetchData'
  },
  data() {
    return {
      statusColor: {
        待出库: 'xa-txt-yellow',
        待领料: 'xa-txt-yellow',
        已领料: 'xa-txt-green',
        已取消: 'xa-txt-secondary'
      },
      list: []
    }
  },
  methods: {
    onShowTip(type) {
      const msgs = {
        tip: `若维修时发现有多余定损坏件，可退还配件
                1.未出库物料，可直接移除 定损坏件
                2.已出库物料，需返还仓库，仓库确认收货后完成退料, 退料说明`,
        item: `已取消的不能申请退料`
      }
      Dialog({
        message: msgs[type]
      })
    },
    onItemTap(item) {
      if (item.status.indexOf('取消') > -1) {
        return this.onShowTip('item')
      }
      const { data } = this.$_extractRequestInfoFormRoute()
      this.$router.push({
        name: 'Return-Material-Select',
        params: {
          ...data,
          mp_guid: item.mp_guid
        }
      })
    },
    async fetchData() {
      const { data } = this.$_extractRequestInfoFormRoute()
      // eslint-disable-next-line no-debugger
      const list = await this.$_request(GetMpList(data))
      this.list = list
    }
  }
}
</script>
<style lang="scss"></style>
